import { formatDate } from '@/lib/utils';
// import { PageViews } from './page-views';
import { Badge } from '@/components/ui/badge';

const BlogPostGrid = ({ posts, authors }: { posts: any; authors: any }) => {
  return (
    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      {posts.map((post: any) => {
        const authorInfo = post.frontmatter.authors.map((author: any) =>
          authors.find(({ slug }: any) => slug === `/authors/${author}`),
        );

        return (
          <div key={post.frontmatter.slug}>
            <article className="flex flex-col items-start justify-between">
              <a href={`/blog/${post.frontmatter.slug}`}>
                <div className="relative w-full">
                  <img
                    className="aspect-[16/9] w-full rounded-2xl bg-base-200 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                    src={post.frontmatter.image}
                    alt={post.frontmatter.title}
                    width={804}
                    height={452}
                  />

                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>

                {/* TODO: Add featured */}
                <div className="max-w-xl">
                  <div className="mt-8 flex flex-wrap items-center gap-2">
                    {post.frontmatter.date && (
                      <time
                        dateTime={post.frontmatter.date}
                        className="text-xs text-muted-foreground"
                      >
                        {formatDate(post.frontmatter.date)}
                      </time>
                    )}
                    {post.frontmatter.categories &&
                      post.frontmatter.categories
                        .map((category: any, index: number) => (
                          <Badge key={index}>{category}</Badge>
                        ))
                        .slice(0, 2)}
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-base-content group-hover:text-primary">
                      <a href={post.slug}>
                        <span className="absolute inset-0" />
                        {post.frontmatter.title}
                      </a>
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-base-content/80">
                      {post.frontmatter.description}
                    </p>
                  </div>
                  <div className="flex flex-row items-center justify-between">
                    {/* Author */}
                    <div className="relative mt-4 flex items-center gap-x-4">
                      {authorInfo[0]?.avatar && (
                        <img
                          className="h-10 w-10 rounded-full bg-base-200"
                          src={authorInfo[0].avatar}
                          alt={authorInfo[0].title}
                          width={40}
                          height={40}
                        />
                      )}

                      <div className="text-sm leading-6">
                        <p className="text-base-conten/80 font-semibold">
                          <a href={'#'}>
                            <span className="absolute inset-0" />
                            {authorInfo[0]?.title}
                          </a>
                        </p>
                        {/* <p>{authors[0]?.twitter}</p> */}
                      </div>
                    </div>
                    {/* Page Views */}
                    {/* <Suspense fallback={null}>
                    <PageViews className="mt-3 mr-2" slug={post.slug} />
                  </Suspense> */}
                  </div>
                </div>
              </a>
            </article>
          </div>
        );
      })}
    </div>
  );
};

export default BlogPostGrid;
